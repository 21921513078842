var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "bg_gray" }),
      _c("headbox", {
        attrs: { title: "待发货", bgColor: "#fff", isborder: "true" }
      }),
      _c(
        "div",
        { staticClass: "centerbox" },
        [
          _c(
            "div",
            [
              _c("van-notice-bar", {
                attrs: {
                  text: "因物流信息存在误差，若存在已发货的订单可自行忽略",
                  "left-icon": "volume-o",
                  mode: "closeable"
                }
              })
            ],
            1
          ),
          !_vm.haveDate
            ? [
                _c("van-empty", {
                  staticClass: "custom-image",
                  attrs: {
                    image: require("@/assets/images/pic_no information@2x.png"),
                    description: "暂无数据!"
                  }
                })
              ]
            : _vm._e(),
          _vm.haveDate
            ? _c("div", {}, [
                _c(
                  "div",
                  { ref: "scroll" },
                  [
                    _vm._l(_vm.produtclist, function(items, index) {
                      return _c("div", { key: index, staticClass: "msgbox" }, [
                        _c("h2", { staticClass: "flex-between h2" }, [
                          _c("div", [_vm._v(_vm._s(items.goods[0].name))]),
                          _c("div", { staticClass: "onstatusbox" }, [
                            _vm._v("待发货")
                          ])
                        ]),
                        _c("div", { staticClass: "flex" }, [
                          _c("div", [_vm._v("定价价格：")]),
                          _c("div", [
                            _vm._v(
                              _vm._s(items.goods[0].price.toFixed(2)) + "元/克"
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "flex" }, [
                          _c("div", [_vm._v("定价克重：")]),
                          _c("div", [
                            _vm._v(
                              _vm._s(items.goods[0].weight.toFixed(2)) + "克"
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "flex" }, [
                          _c("div", [_vm._v("定价时间：")]),
                          _c("div", [
                            _vm._v(_vm._s(_vm._f("formatDate_")(items.time)))
                          ])
                        ]),
                        _c("div", { staticClass: "flex-between" }, [
                          _vm._m(0, true),
                          _c("div", { staticClass: "flexs" }, [
                            _c(
                              "div",
                              {
                                staticClass: "yellowmin",
                                on: {
                                  click: function($event) {
                                    return _vm.pricedetails(
                                      items.goods[0],
                                      items.status,
                                      items.oid,
                                      items.time
                                    )
                                  }
                                }
                              },
                              [_vm._v(" 定价详情 ")]
                            )
                          ])
                        ])
                      ])
                    }),
                    !_vm.isLoadedover
                      ? _c("center", { staticClass: "loadingcenter" }, [
                          _vm._v(" 玩命加载中... ")
                        ])
                      : _vm._e(),
                    _vm.isLoadedover
                      ? _c("center", { staticClass: "loadingcenter" }, [
                          _vm._v(" 已经加载完毕 ")
                        ])
                      : _vm._e()
                  ],
                  2
                )
              ])
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("i", { staticClass: "el-icon-more" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }