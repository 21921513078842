<template>
  <div>
    <!-- bg -->
    <div class="bg_gray"></div>
    <headbox title="待发货" bgColor="#fff" isborder="true"></headbox>
    <div class="centerbox">
      <div>
        <van-notice-bar
          text="因物流信息存在误差，若存在已发货的订单可自行忽略"
          left-icon="volume-o"
          mode="closeable"
        />
      </div>
      <template v-if="!haveDate">
       <van-empty
           class="custom-image"
           :image="require('@/assets/images/pic_no information@2x.png')"
           description="暂无数据!"
         />
      </template>
      <div class="" v-if="haveDate">
        <!-- 待发货 -->
        <div ref="scroll">
          <div
            class="msgbox"
            v-for="(items, index) in produtclist"
            :key="index"
          >
            <h2 class="flex-between h2">
              <div>{{ items.goods[0].name }}</div>
              <div class="onstatusbox">待发货</div>
            </h2>
            <div class="flex">
              <div>定价价格：</div>
              <!--  -->
              <div>{{ items.goods[0].price.toFixed(2) }}元/克</div>
            </div>
            <div class="flex">
              <div>定价克重：</div>
              <!-- .toFixed(2) -->
              <div>{{ items.goods[0].weight.toFixed(2) }}克</div>
            </div>
            <div class="flex">
              <div>定价时间：</div>
              <div>{{ items.time | formatDate_ }}</div>
            </div>
            <div class="flex-between">
              <div><i class="el-icon-more"></i></div>
              <div class="flexs">
                <!-- <div class="gosendproduct">去发货</div> -->
                <div
                  class="yellowmin"
                  @click="
                    pricedetails(
                      items.goods[0],
                      items.status,
                      items.oid,
                      items.time
                    )
                  "
                >
                  定价详情
                </div>
              </div>
            </div>
          </div>
          <center v-if="!isLoadedover" class="loadingcenter">
            玩命加载中...
          </center>
          <center v-if="isLoadedover" class="loadingcenter">
            已经加载完毕
          </center>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatDate_ } from "@/utils/filters";
import headbox from "@/components/head.vue";
import * as delivery from "@/utils/api";
export default {
  data() {
    return {
      produtclist: [],
      // 当前页数
      indexPage: 0,
      isLoadedover: false,
      haveDate: false
    };
  },
  filters: {
    formatDate_
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  },
  mounted() {
    setTimeout(() => {
      window.addEventListener("scroll", this.onScroll);
    }, 500);
    this.getData();
  },

  methods: {
    getData() {
      delivery.mortgageApi("UNDELIVERED", this.indexPage).then(res => {
        
        // 加载更多
        if (this.adddata) {

          if (res.content.length <= 3) {
            this.isLoaded = true;
            this.isLoadedover = true;
            const newarr_ = [...this.produtclist, ...res.content];
            this.produtclist = newarr_;
          } else {
            const newarr = [...this.produtclist, ...res.content];
            this.produtclist = newarr;
            this.isLoaded = false;
          }
        } else {
          if (res.content.length <= 0) {
            this.haveDate = false;
          } else {
            // 初始化数据
            this.produtclist = res.content;
            this.haveDate = true;
          }
        }
      });
    },
    pricedetails(productx, type, oid, time) {
      localStorage.setItem("product", JSON.stringify(productx));
      this.$router.push({
        name: "pricingDetails",
        query: { tyPe: type, Oid: oid, time: time }
      });
    },
    onScroll() {
      const than = this;
      //可滚动容器的高度
      const innerHeight = this.$refs.scroll.clientHeight;
      //屏幕尺寸高度
      const outerHeight = document.documentElement.clientHeight;
      //可滚动容器超出当前窗口显示范围的高度
      const scrollTop = document.documentElement.scrollTop;
      //scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现innerHeight < (outerHeight + scrollTop)的情况，严格来讲，是接近底部。
      // console.log(innerHeight + " " + outerHeight + " " + scrollTop);
      if (innerHeight <= outerHeight + scrollTop) {
        if (this.isLoaded) {
          return false;
        }
        than.adddata = true;
        than.isLoaded = true;
        than.indexPage++;
        than.getData();
      }
    }
  },
  components: {
    headbox
  }
};
</script>
<style lang="scss" scoped="true">
::v-deep .van-notice-bar {
  height: 72px;
  font-size: 24px;
  background: #f5ebd7;
  color: #fbac1e;
  i {
    font-size: 30px;
    margin-right: 5px;
  }
}
::v-deep{
      .van-empty__image{
        width: 420px;
        height: 260px;
    }
}
.gosendproduct {
  background: #ffb606;
  color: #fff;
  border-radius: 32px;
  width: 160px;
  height: 64px;
  text-align: center;
  line-height: 64px;
  margin-right: 26px;
  font-size: 28px;
}
.flexs {
  display: flex;
}
.bg_gray {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #f7f7f7;
}
.centerbox {
  position: relative;
  z-index: 2;
}
.statusbox {
  color: #2e9bfb;
  background: #d0eeff;
  font-size: 28px;
  border-radius: 8px;
  padding: 0 10px;
  font-weight: normal;
}
.onstatusbox {
  color: #fc4e7f;
  background: #fcdae2;
  font-size: 28px;
  border-radius: 8px;
  padding: 0 10px;
  font-weight: normal;
}
.overstatusbox {
  color: #999999;
  background: #f0f0f0;
  font-size: 28px;
  border-radius: 8px;
  padding: 0 10px;
  font-weight: normal;
}
.flex-between {
  align-items: center;
  i {
    font-size: 48px;
    color: #cccccc;
  }
}

.yellowmin {
  color: #ffb606;
  border: 1px solid #ffb606;
  border-radius: 30px;
  font-size: 28px;
  width: 160px;
  height: 64px;
  line-height: 64px;
  text-align: center;
  // margin-bottom: 30px;
}
.msgbox {
  background: #fff;
  padding: 0 30px 30px;
  width: 690px;
  font-size: 28ox;
  border-radius: 12px;
  box-shadow: 0 0 0 3px #ececec;
  margin: 25px auto 30px;

  h2 {
    font-size: 32px;
    margin-bottom: 30px;
    padding-top: 30px;
  }
  .flex {
    margin-bottom: 30px;
    div:nth-child(1) {
      color: #cccccc;
    }
    div:nth-child(2) {
      color: #333333;
    }
  }
}
.tab_title {
  background: #f7f7f7;
  color: #999999;
  font-size: 26px;
  span {
    padding: 20px 0px 10px;
  }
  .active {
    position: relative;
    // color:#FFB606;
    &::after {
      content: "";
      width: 100%;
      height: 5px;
      border-radius: 4px;
      background: #ffb606;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
.iconbox {
  width: 125px;
}
.titlebox {
  font-size: 30px;
}
.msg_list {
  padding: 30px 0;
  border-bottom: 1px solid #cdcdcd;
  .time {
    position: absolute;
    right: 0;
    color: #b3b3b3;
    font-size: 24px;
    font-weight: normal;
    top: 35px;
    right: 30px;
  }
}
.details {
  font-size: 24px;
  margin-top: 15px;
  color: #b3b3b3;
}
</style>
